import logo from "./logo.svg";
import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./home/Home";
import Footer from "./footer/Footer";
import Demo from "./demo/Demo";
import Contact from "./contact/Contact";

import Navbar from "./nav/Navbar";
import Service from "./service/Service";
import Facebook from "./service/facebook/Facebook";
import Graogics from "./service/graphics/Graphic";
import Google from "./service/google/Google";
import Instagram from "./service/insta/instagram";
import Mobile from "./service/mobile/Mobile";
import Website from "./service/website/Website";
import Software from "./service/soft/Showf";
import About from "./about/About";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />

          {/* <Route path="demo" element={<Demo />} /> */}
          <Route path="service" element={<Service />}>
            <Route index element={<Website />} />
            <Route path="fb" element={<Facebook />} />
            <Route path="insta" element={<Instagram />} />
            <Route path="google" element={<Google />} />
            <Route path="ghraphic" element={<Graogics />} />
            <Route path="website" element={<Website />} />
            <Route path="mobile" element={<Mobile />} />
            <Route path="soft" element={<Software />} />
          </Route>
          <Route path="softMob" element={<Software />} />
          <Route path="fbMob" element={<Facebook />} />
          <Route path="instaMob" element={<Instagram />} />
          <Route path="googleMob" element={<Google />} />
          <Route path="ghraphicMob" element={<Graogics />} />
          <Route path="websiteMob" element={<Website />} />
          <Route path="mobileMob" element={<Mobile />} />
          {/* <Route path="contact" element={<Contact />} /> */}
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
